<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <card class="card-block card-stretch card-height print rounded" :headerClass="header.status == 'VOID' ? 'bg-dark header-invoice' : 'bg-primary header-invoice'">
          <template v-slot:headerTitle>
            <h4 class="card-title mb-0">SPT {{ header.no_spt }} ({{ header.status }})</h4>
          </template>
          <template v-slot:headerAction>
            <div class="invoice-btn">
              <button v-if="header.spt_file_id && !header.voided_at" type="button" class="btn btn-primary active mr-2" @click="cetakSPTHandle()"><i class="las la-print"></i> Cetak SPT</button>
              <b-button variant="danger" @click.prevent="sptHandle()"><i class="las la-chevron-left"></i> Kembali</b-button>
            </div>
          </template>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-12 mb-2">
                <b-alert :show="!header.finished_at && header.settled_at" variant="warning">
                  <div class="mm-alert-icon">
                    <i class="ri-alert-line"></i>
                  </div>
                  <div class="mm-alert-text"><b>SPT belum selesai!</b> 
                    <div>SPT dianggap <b>Selesai</b> jika sudah <b>Cetak Laporan</b>. </div> 
                  </div>
                </b-alert>
                <b-alert :show="header.voided_at" variant="dark">
                  <div class="mm-alert-icon">
                    <i class="las la-window-close"></i>
                  </div>
                  <div class="mm-alert-text"><b>SPT VOID!</b> 
                    <p>Tanggal: {{ header.voided_at }}</p>
                    <p>Alasan: {{ header.void_remark }}</p>
                  </div>
                </b-alert>
                <div class="table-responsive-sm">
                  <table class="table no-border">
                    <tbody>
                      <tr>
                        <td width="15%"><strong>Pelaksana</strong></td>
                        <td><h5><b-badge variant="light">{{ header.pelaksana_text }}</b-badge></h5></td>
                        <td><strong>Penandatangan</strong></td>
                        <td><h5><b-badge variant="light">{{ header.pttd_text }}</b-badge></h5></td>
                      </tr>
                      <tr>
                        <td><strong>PPTK</strong></td>
                        <td><h5><b-badge variant="light">{{ header.pptk_text }}</b-badge></h5></td>
                        <td><strong>Pembantu Bendahara</strong></td>
                        <td><h5><b-badge variant="light">{{ header.bendahara_text }}</b-badge></h5></td>
                      </tr>
                      <tr>
                        <td><strong>Asal</strong></td>
                        <td>{{ header.daerah_asal }}</td>
                        <td><strong>Tanggal Berangkat</strong></td>
                        <td>{{ header.tgl_berangkat }}</td>
                      </tr>
                      <tr>
                        <td><strong>Tujuan</strong></td>
                        <td>{{ header.daerah_tujuan }}</td>
                        <td><strong>Tanggal Kembali</strong></td>
                        <td>{{ header.tgl_kembali }}</td>
                      </tr>
                      <tr>
                        <td><strong>Alat Transportasi</strong></td>
                        <td colspan="3"> {{ header.transportasi }}</td>
                      </tr>
                      <tr>
                        <td><strong>Anggaran</strong></td>
                        <td colspan="3">{{ header.anggaran_text }} / {{ header.anggaran_name }}</td>
                      </tr>
                      <tr>
                        <td> <strong>Dasar Pelaksana</strong> </td>
                        <td colspan="3">{{ header.dasar_pelaksana }}</td>
                      </tr>
                      <tr>
                        <td> <strong>Untuk</strong> </td>
                        <td colspan="3">{{ header.untuk }}</td>
                      </tr>
                      <tr v-if="header.finished_at">
                        <td> <strong>Hasil Perjalanan Dinas</strong> </td>
                        <td colspan="3" v-html="header.hasil"></td>
                      </tr>
                      <tr v-if="header.finished_at">
                        <td> <strong>Saran</strong> </td>
                        <td colspan="3" v-html="header.saran"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr />
              <div class="d-none d-sm-block my-2 mx-2 container-fluid">
                <div class="row float-left">
                  <button type="submit" class="btn btn-xs-block btn-success my-2 mx-2" v-if="header.can_generate && !header.voided_at" @click.prevent="showRekap()">Cetak Kwitansi</button>
                  <button type="submit" class="btn btn-xs-block btn-success my-2 mx-2" v-if="header.settled_at != null && !header.voided_at" @click.prevent="rekapHandle()">Unduh Kwitansi</button>
                  <button type="submit" class="btn btn-xs-block btn-warning my-2 mx-2" v-if="header.settled_at != null && !header.voided_at" @click.prevent="showLaporan()">Cetak Laporan</button>
                  <button type="submit" class="btn btn-info my-2 mx-2" v-if="!header.proceed_at" @click.prevent="showProses()">Proses SPT</button>
                  <button type="submit" class="btn btn-secondary my-2 mx-2" v-if="header.can_finish && header.settled_at == null && !header.voided_at" @click.prevent="handleProses('selesai')">Selesai</button>
                  <button type="submit" class="btn btn-success my-2 mx-2" v-if="header.can_edit && !header.voided_at" @click.prevent="handleEdit()">Edit SPT</button>
                </div>
                <div class="row float-right">
                  <button type="submit" class="btn btn-xs-block btn-dark" v-if="header.can_void" @click.prevent="voidSPT()">VOID</button>
                  
                </div>
              </div>
              <div class="d-block d-sm-none my-2 mx-2 container-fluid">
                <div class="row float-left">
                  <button type="submit" class="btn btn-xs-block btn-success my-2 mx-2" v-if="header.can_generate && !header.voided_at" @click.prevent="showRekap()" title="Rekap Biaya"><i class="dripicons dripicons-print"></i></button>
                  <button type="submit" class="btn btn-xs-block btn-success my-2 mx-2" v-if="header.settled_at != null && !header.voided_at" @click.prevent="rekapHandle()" title="Rekap Biaya"><i class="dripicons dripicons-print"></i></button>
                  <button type="submit" class="btn btn-xs-block btn-warning my-2 mx-2" v-if="header.settled_at != null && !header.voided_at" @click.prevent="laporanHandle()" title="Cetak Laporan"><i class="dripicons dripicons-print"></i></button>
                  <button type="submit" class="btn btn-info my-2 mx-2" v-if="!header.proceed_at" @click.prevent="showProses()" title="Proses SPT"><i class="dripicons dripicons-direction"></i></button>
                  <button type="submit" class="btn btn-secondary my-2 mx-2" v-if="header.can_finish && header.settled_at == null && !header.voided_at" @click.prevent="handleProses('selesai')" title="Selesai"><i class="dripicons dripicons-lock"></i></button>
                  <button type="submit" class="btn btn-success my-2 mx-2" v-if="header.can_edit && !header.voided_at" @click.prevent="handleEdit()" title="Edit SPT"><i class="dripicons dripicons-pencil"></i></button>
                </div>
                <div class="row float-right">
                  <button type="submit" class="btn btn-dark my-2 mx-2" @click.prevent="voidSPT()" title="VOID" v-if="header.can_void"><i class="dripicons dripicons-document-delete"></i></button>
                </div>
              </div>
            </div>
            <vue-good-table 
              :columns="columns" 
              :line-numbers="true" 
              :rows="rows"
              :group-options="{
                enabled: true,
                headerPosition: 'bottom',
              }">
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <b-button size="sm" @click="editHandle(props)" v-if="header.completed_at != null" variant="primary" class="mr-2" title="Edit">
                    <i class="dripicons dripicons-pencil-alt"></i> Kwitansi
                  </b-button >
                  <b-button size="sm" @click="downloadSPPDHandle(props)" variant="info" v-if="props.row.sppd_file_id != null && !header.voided_at" class="mr-2" title="Cetak SPPD">
                    <i class="dripicons dripicons-pencil-alt"></i>Cetak SPPD
                  </b-button >
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
    <!-- MODAL DELETE -->
    <b-modal :no-close-on-backdrop="true" id="modalProses" :title="modalTitle">
      <p>{{modalMsg}}.<br> <strong>{{ modalConfirm }}</strong></p>
      <template #modal-footer="{ cancel }">
        <b-button variant="primary" @click="cancel()">
          Batal
        </b-button>
        <b-button variant="danger" @click="handleProses(modalAct)">
          Proses
        </b-button>
      </template>
    </b-modal>
    <!-- MODAL Transport -->
    <b-modal :no-close-on-backdrop="true" size="lg" id="modalLaporan" title="Cetak Laporan">
      <form>
        <div class="form-group">
          <label for="name">Hasil Perjalanan Dinas<strong>*</strong></label>
          <vue-editor v-model="header.hasil" class="hide-toolbar" ></vue-editor> 
        </div>
        <div class="form-group">
          <label for="name">Saran</label>
          <vue-editor v-model="header.saran" class="hide-toolbar"></vue-editor> 
        </div>
      </form>
      <template #modal-footer="{ cancel }">
        <b-button variant="danger" @click="cancel()">
          Batal
        </b-button>
        <b-button variant="primary" @click="laporanHandle()">
          Simpan
        </b-button>
      </template>
    </b-modal>
    <!-- MODAL Void -->
    <b-modal :no-close-on-backdrop="true" size="md" id="modalVoid" title="Void SPT">
      <form>
        <div class="form-row">
          <b-col md="12" class="form-group mb-3">
            <label for="void">Alasan Void<strong>*</strong></label>
            <textarea rows="5" class="form-control" v-model="form.void_remark" id="void"></textarea>
          </b-col>
        </div>
      </form>
      <template #modal-footer="{ cancel }">
        <b-button variant="danger" @click="cancel()">
          Batal
        </b-button>
        <b-button variant="dark" @click="voidSPTHandle()">
          Void
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import 'vue-good-table/dist/vue-good-table.css'
  import { getDataService, patchDataService, putDataService, postDataService } from '../../store/modules/crudservices'
  import { VueGoodTable } from 'vue-good-table'
  import { url_img } from '../../config/config'
  import { VueEditor } from 'vue2-editor'
  import { cekAkses } from '../../config/authenticate'

  export default {
    name:'SPTList',
    data () {
      return {
        form:{
          void_remark: null
        },
        modalTitle: '',
        modalMsg: '',
        modalConfirm: '',
        modalAct: '',
        columns:[{
            label: 'NIP',
            field: 'nip'
          }, {
            label: 'Nama',
            field: 'full_name'
          }, {
            label: 'Pengeluaran',
            field: 'total_biaya',
            tdClass: 'text-right',
            formatFn: this.formatFn,
          }, {
            label: 'Aksi',
            field: 'action',
            sortable: false,
            width: '350px',
            tdClass: 'text-center',
          }
        ],
        spt_id: null,
        rows: [],
        header: [],
      }
    },
    methods: {
      refreshLists(){
        const url = `/spt/${this.$route.params.id}/sppd-grid`
        getDataService(url).then(response => {
          this.rows = response.data
        })
      },
      getSpt(id) {
        getDataService(`/spt/${id}`).then(response => {
          this.header = response.data
        })
      },
      showLaporan() {
        this.$bvModal.show('modalLaporan')
      },
      voidSPT() {
        this.$bvModal.show('modalVoid')
      },
      voidSPTHandle() {
        const url = `/spt/${this.$route.params.id}/void`
        const _data = {
          void_remark: this.form.void_remark
        };
        postDataService(url, _data).then(response => {
          if(response.data.success) {
            this.$bvModal.hide('modalVoid')
            this.refreshLists()
            this.getSpt(this.$route.params.id)
          }
        })
      },
      formatFn: function(value) {
        return Number(value).toLocaleString();
      },
      editHandle(data) {
        let url = `/spt/${this.$route.params.id}/sppd/${data.row.id}/${data.row.pegawai_id}`
        this.$router.push(url) 
      },
      sptHandle() {
        this.$router.push('/spt') 
      },
      cetakSPTHandle() {
        const url = `/spt/${this.$route.params.id}/cetak`
        getDataService(url).then(response => {
          let link = document.createElement("a")
          link.href = url_img + response.data
          link.download = (new Date).toLocaleDateString() + "_spt.pdf"
          link.target = "_blank"
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
      },
      showProses(){
        this.modalAct = 'proses'
        this.modalTitle = 'Proses SPT'
        this.modalMsg = 'SPT tidak dapat diubah kembali setelah diproses'
        this.modalConfirm = 'apakah anda yakin?'
        this.$bvModal.show('modalProses')
      },
      showRekap(){
        this.modalAct = 'kwitansi'
        this.modalTitle = 'Cetak Rekap Biaya'
        this.modalMsg = 'Pastikan kwitansi sudah diisi dengan lengkap dan benar'
        this.modalConfirm = 'SPT yang sudah cetak kwitansi tidak dapat diubah kembali'
        this.$bvModal.show('modalProses')
      },
      handleEdit() {
        let url = `/spt/${this.$route.params.id}/edit`
        this.$router.push(url) 
      },
      handleProses(action) {
        if(action == 'kwitansi') {
          this.rekapHandle()
        } else {
          this.$bvModal.hide('modalProses')
          const url = `/spt/${this.$route.params.id}/${action}`
          const _data = {'_method': 'PATCH'}
          patchDataService(url, _data).then(response => {
            if(response.data.success) {
              this.$bvModal.hide('modalProses')
              this.refreshLists()
              this.getSpt(this.$route.params.id)
            }
          })
        }
      },
      laporanHandle() {
        this.$bvModal.hide('modalLaporan')
        const _data = {
          _method: 'PUT',
          saran: this.header.saran,
          hasil: this.header.hasil,
        }
        const url = `/spt/${this.$route.params.id}/laporan`
        putDataService(url , _data).then(response => {
          if(response.data.data.length > 0) {
            let link = document.createElement("a")
            link.href = url_img + response.data.data
            link.download = (new Date).toLocaleDateString() + "_laporan.pdf"
            link.target = "_blank"
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          } else {
            this.$toast.open({
              message: 'Kesalahan! Tidak dapat memproses.',
              type: 'error',
            })
          }
        })
      },
      rekapHandle() {
        this.$bvModal.hide('modalProses')
        const url = `/spt/${this.$route.params.id}/kwitansi`
        getDataService(url).then(response => {
          if(response.data.length > 0) {
            let link = document.createElement("a")
            link.href = url_img + response.data
            link.download = (new Date).toLocaleDateString() + "_kwitansi.pdf"
            link.target = "_blank"
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          } else {
            this.$toast.open({
              message: 'Kesalahan! Tidak dapat memproses.',
              type: 'error',
            })
          }
        })
      },
      downloadSPPDHandle(data) {
        const url = `/spt/cetak-sppd/${data.row.id}`
        getDataService(url).then(response => {
          let link = document.createElement("a")
          link.href = url_img + response.data
          link.download = (new Date).toLocaleDateString() + "_sppd.pdf"
          link.target = "_blank"
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
      },
      validasi(perms) {
        return cekAkses(perms)
      },
    },
    components: {
      VueGoodTable, VueEditor
    },
    created() {
      this.refreshLists()
      this.getSpt(this.$route.params.id)
    },
    mounted() {
      // this.spt_id = this.$route.params.id
      // this.refreshLists()
      // setTimeout(() => {
      //   this.loaded = true
      // }, 500);
    }
  }
</script>

<style >
  .hide-toolbar .ql-toolbar {
    display: none;
  }
</style>